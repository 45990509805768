<template>
    <div>
        <fromSearch :object_='seriesList_' @searchFun='getList' />
        <avue-crud :option="tableOption" :data="page.list" :page="page" :table-loading="listLoading"
            @size-change="sizeChange" @row-update="rowUpdate"
            @current-change="currentChange" >
            <template #menu-left>
                <el-button type="primary"   @click="openView(0)">{{$t('button.add')}} </el-button>
            </template>
            <template #createTime="scope">
                {{turnTimeFun(scope.row.createTime)}}
            </template>
            <template #collectionImg='scope'>
                <img :src="scope.row.collectionImg" style="width: 50px;"/>
            </template>
        </avue-crud>   

        <el-dialog v-model="innerVisible1" title="新增外部系列" width="500">
            <addView v-if='pageStyle === 0' @successFun='successFun' ref='addView_'/>
            <template #footer>
                <el-button    @click="innerVisible1 = false">{{$t('button.closeQ')}} </el-button>
                <el-button type="primary"   @click="completeFun">{{$t('button.tijiao')}}</el-button>
            </template>
        </el-dialog>
    </div>
</template>
<script setup>
    import { ref,nextTick,getCurrentInstance } from 'vue'
    import { seriesList } from '@/const/from/nft/seriesList'
    import fromSearch from '@/components/fromSearch.vue'
    import { outSideWork } from '@/const/crud/coin/outSideWork'
    import addView from './add'
    import { turnTimeFun } from '@/utils/util.js'   
    import Api_ from '@/api/index'
    const { $t } = getCurrentInstance().proxy;

    import mixins from '@/views/mixins/page'
    let pageObj = mixins(['getNftcollectioninfoList'],{createFrom:2})
    const {page,listLoading,sizeChange,currentChange,getList} = pageObj

    const tableOption = ref(outSideWork); 
    const seriesList_ = ref(seriesList);

    const pageStyle = ref(0)
    const innerVisible1 = ref(false)
    const addView_ = ref(null)
    
    const completeFun = ()=>{
        addView_.value.completeFun()
    }
    const openView = (e,parm)=>{ 
        innerVisible1.value = true
        pageStyle.value = e
        nextTick(()=>{
            addView_.value.setData1({collectionId:parm.collectionId})
        })
    }
    const successFun = ()=>{
        innerVisible1.value = false
        getList(1)
    }
    const rowUpdate = (row,index,done)=>{
        Api_.putnftcollectioninfo(row).then(res=>{
            if(res.code === 0){
                getList(1)
            }
        })
        done()
    }
    getList(1)
</script>