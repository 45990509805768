import il8n from '@/assets/language/index.js';
const { t } = il8n.global;
import website from '@/config/website'

export const outSideWork = {
    border: true,
    menuAlign: 'center',
    align: 'center',
    height: 'auto',
    refreshBtn: false,
    showClomnuBtn: false,
    searchMenuSpan: 6,
    searchLabelWidth: '130',
    labelWidth: '80',
    columnBtn: false,
    addBtn: false,
    // editBtn: false,
    dialogWidth: 500,
    menuWidth: 100,
    delBtn: false,
    menu:true,
    column: [{
        width: 100,
        label: t('currencySetNft.name1'),
        prop: 'tenantName',
        editDisplay: false
    },{
        width: 100,
        label: t('currencySetNft.name2'),
        prop: 'appName',
        editDisplay: false
    }, {
        width: 100,
        label: t('currencySetNft.text6'),
        prop: 'chain',
        editDisplay: false
    }, {
        width:100,
        label: '封面图',
        prop: 'collectionImg',
        type: 'upload',
        listType: 'picture-img',
        span: 24,
        // propsHttp: {
        //     // home: website.urls,
        //     res: 'data',
        //     name: 'url'
        // },
        action: website.urls + '/admin/sys-file/uploadFileToOSS',
        rules: [{
            required: true,
            message: "请上传",
            trigger: "blur"
        }]
    },{
        width:100,
        label: t('currencySetNft.text7'),
        prop: 'protocol',
        editDisplay: false
    }, {
        width:350,
        label: t('nfts.name1'),
        prop: 'contractAddress',
        editDisplay: false
    },{
        width:150,
        label: t('nfts.text13'),
        prop: 'collectionName',
        editDisplay: false
    },{
        label: t('nfts.name2'),
        prop: 'description',
        overHidden: true,
        editDisplay: false
    },
    {
        width: 160,
        label: t('nfts.name18'),
        prop: 'createTime',
        editDisplay: false
    }]
}